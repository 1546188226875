import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faPhone,
    faEnvelope,
    faAngleRight,
    faMapMarkerAlt
} from '@fortawesome/free-solid-svg-icons'
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { device } from "../queries"
import { Link, withPrefix } from "gatsby"

const Icoontje = styled(FontAwesomeIcon)`
    color: var(--green-secondary);
    font-size: 30px;
    margin: 5px 0;


`

const Linkitems = styled.span`
    li{
        vertical-align: super;
        margin-bottom: 7px;

        svg{
            margin-right: 5px;
        }

    }
`
const Container = styled.div`
    width: 100%;
    margin: 0 auto;
    background-color: var(--dark-background);
    background-repeat: repeat-x;
    background-size: auto;
`

const InnerContainer = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding: 15px;
    padding: 50px 15px 60px 15px;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    @media ${device.tablet}{
        flex-direction: column;
    }

    h4{
        margin-bottom: 20px;
        color: white;
        font-weight: 900;
        font-size: 20px;
    }
    p{
        color: #ecf5f2;
        font-weight: 300;
        font-size: 16px;
        letter-spacing: normal;

    }
    b{
        font-weight: 900;
        color: white;
    }
    ul{
        color: rgb(195 214 209);
        padding: none;
        padding-inline-start: 0;
        }
    li{
        padding-inline-start: 0;
        
    }
    a{
        text-decoration: none;
        font-weight: 300;
        transition: all 0.25s cubic-bezier(.44,0,.17,1);
        color: #ecf5f2;
        :hover{
            color: var(--green-secondary);
            
        }

    }
`
const Icoonlink = styled(FontAwesomeIcon)`
    color: white;
    font-size: 14px;
    display: inline-block;
    margin-right: 5px;
    transition: all 0.25s cubic-bezier(.44,0,.17,1);
    a:hover &{
        color: var(--green-secondary);
        transform: translateX(5px);
    }
` 

const Margintext = styled.span`

    vertical-align: super;
    padding-left: 5px;
`

const Green = styled.span`
    color: var(--green-secondary);
    font-weight: 300;

`
const Digital = styled.span`
    color: white;
    font-weight: 300;
`
const Column = styled.div`
    width: 23%;
    :nth-of-type(1){
    padding-right: 20px;
    }
    @media ${device.tablet}{
        :nth-of-type(1){
            padding-right: 0;
        }
    }
    :nth-child(3){
    }


@media ${device.tablet}{
        width: 100%;
        padding-bottom: 40px;
    }
`


const Bottom = styled.div`
    width: 100%;
    background-color: rgba(45,52,53,1);
    margin: 0 auto;
    div{
        margin: auto;
        text-align: center;
        padding: 20px;
        box-sizing: border-box;
    }
    p{
        color: rgba(255,255,255,0.7);
        margin: 0 auto;
        font-weight: 500;
        letter-spacing: normal;
        font-size: 14px;
        letter-spacing: 2px;
    }
`

const Contactvak = styled.div`
    {
    }


    
    p{
        margin-bottom: 7px;
        letter-spacing: normal;
    }
`



const BackgroundSection = () => {

    const data = useStaticQuery(graphql`
    query menuitems {
      site {
        siteMetadata {
          title
          menuLinks {
            name
            link
            subMenu {
              name
              link
            }
          }
        }
      }
    }
  
  `)

    return (
        <>
            <Container>
                <InnerContainer>
                    <Column>
                        <h4>OVER ONS</h4>
                        <p>
                            Green Digital is gespecialiseerd in inkoop, hergebruik en recycling van ICT apparatuur.
                            Wij bieden afvoer, discrete verwerking en aantrekkelijke restwaardes.
                        </p>
                    </Column>
                    <Column>
                        <h4>DIENSTEN</h4>
                        <ul>
                            <Linkitems>
                                {data.site.siteMetadata.menuLinks.map( mainlink =>(
                                mainlink.name.toString() == "Diensten" ? (
                                        mainlink.subMenu.map( subLink =>
                                            <li><Link to={subLink.link}><Icoonlink icon={faAngleRight} />{subLink.name}</Link></li>
                                        )
                                ) :<></>
                                ))}

                            </Linkitems>
                        </ul>
                    </Column>
                    <Column>
                        <h4>INFORMATIE</h4>
                        <ul>
                            <Linkitems>
                                {data.site.siteMetadata.menuLinks.map( mainlink =>(
                                mainlink.name.toString() == "Informatie" ? (
                                        mainlink.subMenu.map( subLink =>
                                            <li><Link to={subLink.link}><Icoonlink icon={faAngleRight} />{subLink.name}</Link></li>
                                        )
                                ) :<></>
                                ))}

                            </Linkitems>
                        </ul>
                    </Column>
                    <Column>
                        <h4>CONTACT</h4>
                        <Contactvak>
                            <p> Green Digital BV</p>
                        </Contactvak>
                        <Contactvak>
                            <p><Icoonlink icon={faMapMarkerAlt} />Mars 14</p>
                            <p><Icoonlink icon={faMapMarkerAlt} /> 8448 CP Heerenveen</p>
                        </Contactvak>
                        <Contactvak>
                            <p> <Icoonlink icon={faPhone} />+31 (0) 513201220</p>
                            <p> <Icoonlink icon={faEnvelope} />info@greendigital.nl</p>

                        </Contactvak>
                        <Contactvak>
                            <p>KVK: 75319365</p>
                            <p>BTW/VAT: NL860236638B01</p>

                        </Contactvak>

                    </Column>
                </InnerContainer>



            </Container>
            <Bottom>
                <div>
                    <p>| © 2021 Green Digital BV | Alle rechten voorbehouden | </p>
                </div>
            </Bottom>
        </>
    )

}

const Footer = styled(BackgroundSection)`


`

export default Footer;