/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it


const React = require("react");
const ApolloProviderGet = require("react-apollo");
const ApolloProvider = ApolloProviderGet.ApolloProvider;
const clientGet = require("./src/context/ApolloContext");
const client = clientGet.client;


exports.wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>{element}</ApolloProvider>
);


exports.onInitialClientRender = () => {
}

exports.shouldUpdateScroll = () => {
    return false;
  };