import { Link, withPrefix } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Image from "./image.js"
import styled from "styled-components"
import { device } from "./../queries"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Sitelogo from "./sitelogo"
import { useSpring, animated } from 'react-spring'
import {
  faPhone,
  faEnvelope,
  faArrowCircleRight,
  faClock
} from '@fortawesome/free-solid-svg-icons'

const Logo = styled.div`
width: 200px;
text-orientation: center;
display: inline-block;
svg{
  transform: translateY(4px);
}
@media ${device.laptopL}{
  padding-left: 15px;
}
`
const Flexfo = styled.div`
  display: flex;
  float: right;
  width: 100%;
  max-width: 800px;
  justify-content: space-around;
  align-items: center;
  p{
    color: white;
    font-weight: 900;
    font-size: 13px;
  }
  p:nth-child(1){
    color: rgba(255,255,255,0.8);
    font-weight: 400;

  }
  span{
    display: inline-block;
  }
  margin: 10px 0;
`


const Coolicon = styled(FontAwesomeIcon)`
  padding-right: 5px;
  color: white;
  font-size: 15px;
  vertical-align: 0;
`

const InnerCont = styled.div`
  margin: 0 auto;
  margin-top: -4px;
  max-width: 1400px;
  display: flex;
  justify-content: space-between;
  align-items: center; 

  
`


const Container = styled.div`
  margin: 0 auto;
  background-color: var(--dark-background);
  max-width: 1400px;
`
const Topinfo = styled(animated.div)`
  margin: 0 auto;
  background-color: var(--dark-background);
  display: inline-block;
  width: 100%;
  @media ${device.tablet}{
    display: none;
  }


`


const Pinfo = styled.p`
  margin-left: 20px;
`

const HeadCont = styled.div`
  width: 100%;
  z-index: 300;
  background: linear-gradient( to right, #ffffffff, #ffffff77) ;
  box-shadow: inset 0px 0px 40px rgba(0, 0, 0, 0.20);
  position: fixed;
  top: 0;
  backdrop-filter: blur(5px);
`
const Absoluteborder = styled.div`
  position: absolute;
  width: 100%;
  opacity: 0;
  height: 100%;
  box-sizing: border-box;
  transition: all 0.25s cubic-bezier(.44,0,.17,1);

   
`

const PhantomCheck = styled.input`
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  z-index:100;
`
const Navigation = styled.div`
  max-width: 600px;
  width: 100%;
  height: 100%;
  display: inline-block;
  @media ${device.laptopL}{
        margin-right: 20px;
  }
  
`
const Submenu = styled.div`
  position: relative;
 overflow: hidden;
 transition: all 0.25s cubic-bezier(.44,0,.17,1);
  margin: 0;
  height: auto;
  background-color: white;
  box-shadow: 0 10px 10px rgba(0,0,0,0.2);
  max-height: 0;
  width: auto;
  z-index: 1;

 ul{
  background-color: #ffffffbb;
  backdrop-filter: blur(5px);
  list-style-type: none;
 z-index: 20;
  padding: 0;
  box-sizing: border-box;



  width: 100%;



  li{
    transition: background-color 0.25s cubic-bezier(.44,0,.17,1);
    padding: 10px 0;
    color: black;
    z-index: 1;


    a{
      font-size: 15px;
      text-align: left;

    }

    :hover{
      background-color: rgb(207, 241, 206);
    }
  }

 }
`

const Bluribo = styled.div`
  width: auto;
  height: 100%;
  position: absolute;
  backdrop-filter: blur(5px);
  background-color: #ffffffbb;

`

const Innernav = styled.ul`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0;
  margin: 0;
  height: 64px;
  


  
`

const Mainnavitems = styled.li`
    font-size: 20px;
    position: relative;
    height: 100%;
    width: 25%;
    transition: all 0.25s cubic-bezier(.44,0,.17,1);

    :hover{
      background-color: rgb(207 241 206);
    }
    :hover ${Absoluteborder}{
    opacity: 1;

    }
    :hover ${Submenu}{
      max-height: 300px;
    }
  }
    
    a{
      display: inline;
      color: var(--dark-text);
      list-style-type: none;
      text-decoration: none;
      z-index: 4;
      font-weight: 500;
      padding: 0 10px;
      text-align: center;
      height: 100%;
      display: flex;
    flex-direction: column;
    justify-content: space-around;


    }
  }
  @media ${device.tablet}{
    display: none;
  }
`



const Dienstknop = styled.li`
  display: none;
  margin-right: 20px;
  margin: 15px 0;
  
  div{
    width: 30px;
    height: 5px;
    border-radius: 2px;
    background-color: var(--dark-background);
  }
  @media ${device.tablet}{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

  }
  input[type=checkbox]:checked ${PhantomCheck}{
    width: 100%;
    }
  input[type=checkbox]:checked ${PhantomCheck}{
    width: 400%;
    }
`
const Diensten = styled.div`
  font-weight: 500;
  height: fit-content;
  max-height: 0px;
  overflow: hidden;
  position: relative;
  transition: all 0.5s cubic-bezier(.44,0,.17,1);
  border-bottom: 3px solid var(--dark-background);
  li{
      a{
        z-index: 20;
        display: inline-block;
        color: var(--dark-text);
        font-size: 18px;
        font-weight: 500;
        text-decoration: none;
        margin: 15px 15px ;

    &:active{
      }
      
    }
  }
`



class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuclosed: false,
    };
    this.toggleNav = this.toggleNav.bind(this);

  }

  toggleNav() {
    if (this.state.menuclosed === false) {
      document.getElementById("mobnav").classList.add("mobileOpen");
    }
    else {
      document.getElementById("mobnav").classList.remove("mobileOpen");
    }
    this.state.menuclosed = !this.state.menuclosed;
  }



  render() {



    return (
      <>
        <HeadCont>
          <Topinfo>
            <Container><Flexfo>
              <div><span><Pinfo>Bel ons</Pinfo><p><Coolicon icon={faPhone} />{this.props.phone}</p></span></div>
              <div><span><Pinfo>Openingstijden</Pinfo><p><Coolicon icon={faClock} />{this.props.Openingstijden}</p></span></div>
              <div><span><Pinfo>Email</Pinfo><p><Coolicon icon={faEnvelope} />{this.props.email}</p></span></div></Flexfo>
            </Container>
          </Topinfo>
          <InnerCont>
            <Logo>
              <Link to="/">
              <Sitelogo />
              </Link>
            </Logo>
            <Navigation>
              <Innernav>
                {this.props.menuLinks.map(e => (
                  <Mainnavitems>
                    <Link to={e.link}>{e.name}</Link>
                    {
                      e.subMenu && e.subMenu.length > 0 ? (
                        <Submenu>
                          <Bluribo>  </Bluribo>
                          <ul>
                          {e.subMenu.map(items => <li><Link to={items.link}>{items.name}</Link></li>)}
                          </ul>


                        </Submenu>

                      ) : console.log(e.name + " has no subitems")
                    }
                    
                  </Mainnavitems>
                ))}
                <Dienstknop><PhantomCheck type="button" onClick={this.toggleNav} />
                  <div></div>
                  <div></div>
                  <div></div>
                </Dienstknop>
              </Innernav>
            </Navigation>
          </InnerCont>
          <Diensten id="mobnav">
               {this.props.menuLinks.map(e => (
                    
                      e.subMenu && e.subMenu.length > 0 ? (

                          e.subMenu.map(items => <li  onClick={this.toggleNav}><Link to={items.link}>{items.name}</Link></li>)




                      ) : <></>
                    
                    
                 ))}
          </Diensten>
        </HeadCont>

      </>
    )

  }
}
export default Header
