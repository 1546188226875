import React from "react"

const Sitelogo = () => {
    return(
<svg version="1.1" id="Laag_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 397.02 63.68"  >


		<path class="st1" d="M25.23,0.44c9.72,0,15.41,4.25,16.92,5.56l0.07,0.07l0.34,0.31l-2.89,3.59c0,0-5.5-4.97-14.38-4.97
			C14.17,5,5.64,13.76,5.64,25.43c0,11.94,8.39,20.64,19.59,20.64c7.31,0,12.2-5.46,14.27-7.84h-8.56V33.9H46.8v4.34v11.15h-4.44
			l0-8.46l-1.34,1.85L41,42.8c-0.33,0.43-5.97,7.61-15.81,7.82l-0.45,0C11.21,50.63,0.5,40,0.5,25.43
			C0.5,11.49,11.21,0.44,25.23,0.44L25.23,0.44z"/>
<path class="st1" d="M50.5,49.38h4.63V34.95c0-2.18,0.27-4.49,0.95-6.67c1.5-5.04,5.1-8.85,9.94-8.85c0.89,0,1.7,0.14,1.7,0.14
	v-4.56c0,0-0.88-0.2-1.84-0.2c-5.38,0-9.19,3.95-10.82,9.12h-0.14c0,0,0.14-1.16,0.14-2.59v-2.31c0-2.72-1.29-3.88-4.15-3.88h-4.76
	v4.02H49c0.95,0,1.5,0.54,1.5,1.5V49.38L50.5,49.38z"/>
<path class="st1" d="M67.85,32.29c0,10.55,7.76,17.9,17.9,17.9c7.76,0,12.73-4.9,12.73-4.9l-2.25-3.47c0,0-4.01,4.22-10.28,4.22
	c-7.08,0-13.14-4.97-13.27-13.61h26.27c0,0,0.14-1.5,0.14-2.31c0-8.51-4.9-15.79-14.36-15.79C75.54,14.33,67.85,21.06,67.85,32.29
	L67.85,32.29z M72.89,28.76c1.16-6.87,6.13-10.55,11.77-10.55c5.04,0,9.39,3.47,9.66,10.55H72.89L72.89,28.76z"/>
<path class="st1" d="M102.43,32.29c0,10.55,7.76,17.9,17.9,17.9c7.76,0,12.73-4.9,12.73-4.9l-2.25-3.47c0,0-4.02,4.22-10.28,4.22
	c-7.08,0-13.14-4.97-13.27-13.61h26.27c0,0,0.14-1.5,0.14-2.31c0-8.51-4.9-15.79-14.36-15.79
	C110.12,14.33,102.43,21.06,102.43,32.29L102.43,32.29z M107.46,28.76c1.16-6.87,6.13-10.55,11.77-10.55
	c5.04,0,9.39,3.47,9.66,10.55H107.46L107.46,28.76z"/>
<path class="st1" d="M140.2,49.38h4.63v-16.4c0-1.63,0.14-3.2,0.54-4.63c1.57-5.65,6.6-9.67,12.46-9.67c6.6,0,7.42,4.76,7.42,10.14
	v16.4c0,2.93,1.23,4.15,4.15,4.15h4.83v-4.02h-2.86c-0.95,0-1.5-0.48-1.5-1.5V27.6c0-8.44-2.99-13.27-11.5-13.27
	c-8.03,0-12.39,5.51-13.61,8.64h-0.14c0,0,0.14-1.09,0.14-2.45v-1.57c0-2.59-1.29-3.81-4.15-3.81h-4.76v4.02h2.86
	c0.95,0,1.5,0.54,1.5,1.5V49.38L140.2,49.38z"/>
<path class="st2" d="M195.69,45.03c0,3.06,1.29,4.36,4.36,4.36h10.69c14.63,0,24.3-8.85,24.3-24.09c0-15.18-9.66-23.96-24.3-23.96
	h-19.47v5.85h4.42V45.03L195.69,45.03z M202.49,42.03V7.18h7.76c10.62,0,17.7,6.33,17.7,18.1c0,11.84-7.01,18.24-17.7,18.24h-6.26
	C203.04,43.53,202.49,42.98,202.49,42.03L202.49,42.03z"/>
<path class="st2" d="M244.76,8.2h5.92V1.33h-5.92V8.2L244.76,8.2z M244.62,45.03c0,3.06,1.29,4.36,4.36,4.36h6.47v-5.65h-2.79
	c-0.95,0-1.5-0.48-1.5-1.5V19.3c0-3.06-1.29-4.36-4.36-4.36h-6.47v5.65h2.79c0.95,0,1.5,0.48,1.5,1.5V45.03L244.62,45.03z"/>
<path class="st2" d="M260.41,31.35c0,9.94,5.85,17.63,15.38,17.63c5.04,0,8.24-1.91,10.28-5.24h0.14c0,0-0.14,1.09-0.14,2.18v2.31
	c0,6.94-4.9,9.6-10.89,9.6c-5.17,0-9.39-2.31-9.39-2.31l-2.25,5.31c3.4,1.7,7.62,2.86,11.78,2.86c8.71,0,17.36-4.36,17.36-15.72v-26
	c0-0.89,0.61-1.36,1.5-1.36h2.72v-5.65h-6.4c-2.72,0-3.81,1.43-3.81,3.47c0,0.54,0,1.09,0,1.09h-0.14c0,0-2.45-5.38-11.03-5.38
	C265.93,14.13,260.41,21.48,260.41,31.35L260.41,31.35z M277.09,43.26c-6.19,0-9.94-4.9-9.94-12.05c0-7.01,3.4-11.37,9.39-11.37
	c5.31,0,9.73,2.59,9.73,11.57C286.28,40.4,281.86,43.26,277.09,43.26L277.09,43.26z"/>
<path class="st2" d="M305.34,8.2h5.92V1.33h-5.92V8.2L305.34,8.2z M305.2,45.03c0,3.06,1.29,4.36,4.36,4.36h6.47v-5.65h-2.79
	c-0.95,0-1.5-0.48-1.5-1.5V19.3c0-3.06-1.29-4.36-4.36-4.36h-6.47v5.65h2.79c0.95,0,1.5,0.48,1.5,1.5V45.03L305.2,45.03z"/>
<path class="st2" d="M324.53,36.79c0,11.5,8.78,12.86,13.13,12.86c1.43,0,2.32-0.14,2.32-0.14v-5.85c0,0-0.61,0.14-1.57,0.14
	c-2.52,0-7.28-0.88-7.28-7.76V20.25h8.24v-5.31h-8.24V5.48h-6.47v9.46h-4.63v5.31h4.49V36.79L324.53,36.79z"/>
<path class="st2" d="M345.29,39.72c0,6.87,5.79,10.48,11.71,10.48c8.24,0,10.96-6.81,10.96-6.81h0.14c0,0-0.14,0.89-0.14,2.04
	c0,2.45,1.23,3.95,4.29,3.95h6.19v-5.65h-2.79c-0.95,0-1.5-0.48-1.5-1.5V27.6c0-7.62-2.99-13.48-14.16-13.48
	c-3.33,0-11.91,0.82-11.91,6.47v3.4h6.19v-1.97c0-2.25,4.01-2.65,5.65-2.65c5.38,0,7.62,2.18,7.62,8.24v0.34h-1.5
	C360.26,27.94,345.29,28.62,345.29,39.72L345.29,39.72z M351.96,39.38c0-6.33,8.98-6.6,13.95-6.6h1.7v1.16
	c0,5.1-3.61,10.96-9.19,10.96C354.07,44.89,351.96,42.17,351.96,39.38L351.96,39.38z"/>
<path class="st2" d="M386.13,45.03c0,3.06,1.29,4.36,4.36,4.36h6.53v-5.65h-2.79c-0.95,0-1.5-0.48-1.5-1.5V5.69
	c0-3.06-1.29-4.36-4.36-4.36h-6.53v5.65h2.79c0.95,0,1.5,0.48,1.5,1.5V45.03L386.13,45.03z"/>
</svg>
    )
}

export default Sitelogo