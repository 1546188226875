import React from "react";
import { TransitionProvider, TransitionViews } from "gatsby-plugin-transitions";
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Footer from "../components/footer"
import Header from "../components/header"
import "../components/layout.scss"
import BackgroundImage from "gatsby-background-image"
import styled from "styled-components"
import "./../static/fonts/fonts.css"


const ViewsContainer = styled.div`
  min-height: 1000px;
`

const StyledViewContainer = styled(TransitionViews)`

`


const Layout = ({ location, children, className }) => {
  
  const data = useStaticQuery(graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
        menuLinks {
          name
          link
          subMenu {
            name
            link
          }
        }
        vacatures {
          name
          link
        }
      }
    }
  }

`)
    return (
    <TransitionProvider
    location={location}
      mode="immediate"
      enter={{
        opacity: 0,
        transform: "translate3d(0,0 ,0) scale3d(1, 1, 1) rotate(0deg)",
        config: {
          mass: 30,
          tension: 300,
          friction: 15,
          clamp: true
        },
        onRest: () => {
          console.log("Hello, World!");
        }
      }}
      usual={{
        opacity: 1,
        transform: "translate3d(0,0 ,0) scale3d(1, 1, 1) rotate(0deg)",
      }}
      leave={{
        opacity: 0,
        transform: "translate3d(0,0 ,0) scale3d(1, 1, 1) rotate(0deg)",
        config: {
          mass: 2,
          tension: 300,
          friction: 5,
          clamp: true
        }
      }}
    >
      <Header siteTitle={data.site.siteMetadata.title} menuLinks={data.site.siteMetadata.menuLinks} diensten={data.site.siteMetadata.diensten} email="info@greendigital.nl" phone="+31 (0) 513201220" Openingstijden="Ma - Vr: 08:30 - 17:30" />
      <ViewsContainer>
        <StyledViewContainer>
          {children}
        </StyledViewContainer>
      </ViewsContainer>
    </TransitionProvider>
  
    )
  }
  
export default Layout
